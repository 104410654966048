const ReturnsDashboardActionTypes = {
    BEGIN_FETCH: "returns/BEGIN_FETCH",
    FETCH_COMPLETE: "returns/FETCH_COMPLETE",
    FETCH_FAILURE: "returns/FETCH_FAILURE",
    SET_FROM_DATE: "returns/SET_FROM_DATE",
    SET_TO_DATE: "returns/SET_TO_DATE",
    SET_FILTER_OPTIONS: "returns/SET_FILTER_OPTIONS",
    ADD_FILTER: "returns/ADD_FILTER",
    REMOVE_FILTER: "returns/REMOVE_FILTER",
    ADD_VIEW: "returns/ADD_VIEW",
    REMOVE_VIEW: "returns/REMOVE_VIEW",
    RESET_FILTERS: "returns/RESET_FILTERS",
    SET_FILTERED_DATA: "returns/SET_FILTERED_DATA",
    CLEAR_FILTERED_DATE: "returns/CLEAR_FILTERED_DATE",
    SET_SALE_TYPE: "returns/SET_SALE_TYPE",
    SET_SUMMARY_DATA: "returns/SET_SUMMARY_DATA"
};

export default ReturnsDashboardActionTypes;
