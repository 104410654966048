import { FILTER_CATEGORIES } from "../dashboard/utils/dashboard-config";

const url = {
    AUTHENTICATION: {
        LOGIN: "https://www.mocky.io/v2/5d28173e2c000067003ed7be",
        REGISTER: "https://www.mocky.io/v2/5d1de4e63000006800d72350",
        VALIDATE: "https://www.mocky.io/v2/5d286fbd2c000068003eda46"
    },
    DAHSBOARD: {
        HOME: "https://www.mocky.io/v2/5d1de5303000006a00d72354",
        DETAILS: "https://api.brandis.91social.com:8443/api/dv/detail",
        SUMMARY: "https://api.brandis.91social.com:8443/api/dv/summary"
    },
    // NOTE: Product and Catagory are currently the same
    FILTERS: {
        [FILTER_CATEGORIES.DATE]: "",
        [FILTER_CATEGORIES.SELLER_CLASSIFICATION]:
            "https://api.brandis.91social.com:8443/api/lookup/bestSeller",
        [FILTER_CATEGORIES.SEASON]: "https://api.brandis.91social.com:8443/api/lookup/season",
        [FILTER_CATEGORIES.CATEGORY]: "https://api.brandis.91social.com:8443/api/lookup/product",
        [FILTER_CATEGORIES.CHANNEL]: "https://api.brandis.91social.com:8443/api/lookup/channel",
        [FILTER_CATEGORIES.STATE]: "https://api.brandis.91social.com:8443/api/lookup/state",
        [FILTER_CATEGORIES.CITY]: "https://api.brandis.91social.com:8443/api/lookup/city",
        [FILTER_CATEGORIES.PRODUCT]: "https://api.brandis.91social.com:8443/api/lookup/product"
    }
};

export default url;
