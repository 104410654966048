import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header, Footer } from "./common";
import DashboardRoutes from "./DashboardRoutes";

import DashboardActions from "./redux/DashboardActions";

import "./styles/dashboard.scss";
import { DEFAULT_OPTION, DASHBOARD_PATH } from "./utils/dashboard-config";

const DashboardContainer = props => {
    const { setDashboard, selectedOption, dashboard, history } = props;

    const dashboardMenuSelect = ([option]) => {
        setDashboard([option]);
        history.push(DASHBOARD_PATH[option.value]);
    };

    return (
        <div className={DashboardContainer.styles.root}>
            <Header selectedOption={selectedOption} onMenuSelect={dashboardMenuSelect} />
            <div className={DashboardContainer.styles.content}>
                <DashboardRoutes selected={dashboard.selected} />
            </div>
            <Footer />
        </div>
    );
};

DashboardContainer.styles = {
    root: "nos-brandis__dashboard-root",
    content: "nos-brandis__dashboard-content"
};

DashboardContainer.propTypes = {
    setDashboard: PropTypes.func.isRequired,
    selectedOption: PropTypes.array
};

DashboardContainer.defaultProps = {
    selectedOption: DEFAULT_OPTION
};

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

const mapDispatchToProps = dispatch => ({
    setDashboard: payload => {
        dispatch(DashboardActions.setDashboard(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardContainer);
