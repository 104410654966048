import { filter } from "fuzzaldrin";
import { FILTER_CATEGORIES_SERVER_ENUM } from "./dashboard-config";

const isValuePresentInFilter = (filters, value, category) => {
    const searchAbleObject = filters.find(
        item => item.name === FILTER_CATEGORIES_SERVER_ENUM[category],
    );

    if (searchAbleObject && searchAbleObject.values.includes(value)) return true;

    return false;
};

const textFilteredOptions = (options, value) => {
    if (value.length === 0)
        // if length is 0 the text is blank
        // return all options
        return options;

    // The third parameter is the key to be searched
    return filter(options, value, { key: "description" });
};

export { isValuePresentInFilter, textFilteredOptions };
