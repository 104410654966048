import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FilterStrip, FilterSelector, Loading } from "../../common";

// import "./styles/inventory-dashboard.scss";
import InventoryDashboardActions from "./redux/InventoryDashboardActions";
import { INVENTORY_DASHBOARD_FILTERS } from "../../utils/dashboard-config";
import { INVENTORY_DASHBOARD_VIEWS, CHART_VIEW_SERVER_ENUMS } from "../../utils/chart-config";
import ChartsContainer from "../charts/ChartsContainer";
import VERBIAGE from "../../../utils/enums/verbiage";

const InventoryDashboardContainer = props => {
    const {
        setFromDate,
        setToDate,
        dashboard,
        inventory,
        getFilterCategoryOptions,
        addFilterToStore,
        removeFilterFromStore,
        updateView,
        resetFilters,
        applyFilters
    } = props;

    const [isFilterStripExpanded, setIsFilterStripExpanded] = useState(true);

    const handleViewMenuClick = menu => updateView(CHART_VIEW_SERVER_ENUMS[menu]);

    const handleCancelClick = () => setIsFilterStripExpanded(!isFilterStripExpanded);

    const handleResetClick = () => resetFilters();

    const handleApplyClick = () => applyFilters();

    useEffect(() => {
        applyFilters();
    }, [applyFilters, inventory.views, inventory.filters]);

    const chartsContainerStyles = classnames(InventoryDashboardContainer.styles.chartContainer, {
        [InventoryDashboardContainer.styles.loaderContainer]: inventory.isFetching
    });

    return (
        <div className={InventoryDashboardContainer.styles.container}>
            <FilterStrip
              selectedDashboardStore={inventory}
              isFilterStripExpanded={isFilterStripExpanded}
              setFilterStripToggleState={setIsFilterStripExpanded}
            />
            {isFilterStripExpanded && (
                <FilterSelector
                  setToDate={setToDate}
                  setFromDate={setFromDate}
                  selectedDashboardStore={inventory}
                  handleResetClick={handleResetClick}
                  handleApplyClick={handleApplyClick}
                  handleCancelClick={handleCancelClick}
                  viewMenu={INVENTORY_DASHBOARD_VIEWS}
                  addFilterToStore={addFilterToStore}
                  selectedDashboard={dashboard.selected}
                  handleViewMenuClick={handleViewMenuClick}
                  removeFilterFromStore={removeFilterFromStore}
                  filterCategories={INVENTORY_DASHBOARD_FILTERS}
                  getFilterCategoryOptions={getFilterCategoryOptions}
                />
            )}

            {dashboard.selected.length !== 0 && (
                <div className={chartsContainerStyles}>
                    {inventory.isFetching ? (
                        <Loading />
                    ) : (
                        (() =>
                            inventory.views.length > 0 ? (
                                <ChartsContainer
                                  selectedDashboard={dashboard.selected}
                                  records={inventory.records}
                                  views={inventory.views}
                                />
                            ) : (
                                <div className={InventoryDashboardContainer.styles.filterVerbiage}>
                                    {VERBIAGE.PROMPT.VIEWS}
                                </div>
                            ))()
                    )}
                </div>
            )}
        </div>
    );
};

InventoryDashboardContainer.propTypes = {
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired
};

InventoryDashboardContainer.defaultProps = {};

const mapStateToProps = state => ({
    inventory: state.inventory,
    dashboard: state.dashboard
});

const mapDispatchToProps = dispatch => ({
    setFromDate: payload => {
        dispatch(InventoryDashboardActions.setFromDate(payload));
    },
    setToDate: payload => {
        dispatch(InventoryDashboardActions.setToDate(payload));
    },
    getFilterCategoryOptions: category => {
        dispatch(InventoryDashboardActions.getFilterCategoryOptions(category));
    },
    addFilterToStore: payload => {
        dispatch(InventoryDashboardActions.addFilterToStore(payload));
    },
    removeFilterFromStore: payload => {
        dispatch(InventoryDashboardActions.removeFilterFromStore(payload));
    },

    updateView: payload => {
        dispatch(InventoryDashboardActions.updateView(payload));
    },

    resetFilters: () => {
        dispatch(InventoryDashboardActions.resetFilters());
    },

    applyFilters: () => {
        dispatch(InventoryDashboardActions.applyFiltersAndFetchData());
    }
});

InventoryDashboardContainer.styles = {
    container: "nos-brandis__dashboard__common__container-main",
    chartContainer: "nos-brandis__dashboard__common__container-charts",
    loaderContainer: "nos-brandis__dashboard__common__container-loader",
    filterVerbiage: "nos-brandis__dashboard__common__container-filter-verbiage"
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InventoryDashboardContainer);
