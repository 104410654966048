// import crossfilter from "crossfilter2";

const undefinedChartData = [
    [
        {
            x: 0,
            y: 0
        },
        {
            x: 0,
            y: 0
        }
    ],
    [
        {
            x: 0,
            y: 0
        },
        {
            x: 0,
            y: 0
        }
    ]
];
const normalChartData = [
    {
        x: 0,
        y: 6
    },
    {
        x: 1,
        y: 7
    },
    {
        x: 2,
        y: 8
    }
];

const checkForValidData = (data, legend) => {
    if (
        legend.every(val => val === "undefined") ||
        legend.every(val => val === "NA" || val === "0")
    )
        return undefinedChartData;
    return data;
};

const checkForValidLegend = legend => {
    if (
        legend.every(val => val === "undefined") ||
        legend.every(val => val === "NA" || val === "0")
    )
        return ["Data Unavailable"];

    return legend;
};
const formatDataForPieChart = (/* records, view */) => {
    return [];
};

const formatDataForBarChart = (/* records, view, type */) => {
    return {
        data: normalChartData,
        legendItems: []
    };
};

const formatDataForGroupedBarChart = (records, view) => {
    // const dimension = crossfilter(records).dimension(data => data[view]);
    const recordsBySubtype = {};

    // Bucket data by each subtype of the selected view
    records.forEach(record => {
        if (recordsBySubtype[record[view]]) {
            recordsBySubtype[record[view]].push(record);
            recordsBySubtype[record[view]].push({
                ...record,
                tmonth: 201909 // Second object duplicates data to a transaction month of 2019-09
            });
        }
 else {
            // Second object duplicates data to a transaction month of 2019-09
            recordsBySubtype[record[view]] = [record, { ...record, tmonth: 201909 }];
        }
    });

    // console.log(recordsByView);

    const finalData = Object.keys(recordsBySubtype).map(viewType => {
        const unitsPerSubtype = {};

        // Storing data in a key value pair (t month as key) as it makes searching easier
        recordsBySubtype[viewType].forEach(dataRow => {
            const transactionMonth = dataRow.tmonth;
            const unitPerSubtype = dataRow.totalunits;
            if (unitsPerSubtype[transactionMonth])
                // If data for this tmonth exists, sum the units from each subtype row
                unitsPerSubtype[transactionMonth].y += unitPerSubtype;
            else
                unitsPerSubtype[transactionMonth] = {
                    // Else create new data for this tmonth
                    x: transactionMonth,
                    y: unitPerSubtype
                };
        });

        // console.log(dataForViewType)

        // Adding of units is done, now flatten data from object into array
        return Object.keys(unitsPerSubtype).map(
            transactionMonth => unitsPerSubtype[transactionMonth],
        );
    });

    return {
        data: checkForValidData(finalData, Object.keys(recordsBySubtype)),
        legendItems: checkForValidLegend(Object.keys(recordsBySubtype))
    };
};

const formatDataForStackedBarChart = (records, view) => {
    return formatDataForGroupedBarChart(records, view);
};

export {
    formatDataForPieChart,
    formatDataForBarChart,
    formatDataForStackedBarChart,
    formatDataForGroupedBarChart
};

// const something = dimension.group(data => data).reduce(date => data[view]);
// .group()
// .value();
// console.log(
//     "dim",
//     dimension
//         .groupAll()
//         .reduceSum()
//         .value(),
// );
// console.log("som", something);
// console.log("formatter", records, view);
