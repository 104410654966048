import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "../styles/selectable-button.scss";

const SelectableButton = props => {
    const { text, value, handleClick, selected } = props;

    const buttonStyles = classnames(SelectableButton.styles.normal, {
        [SelectableButton.styles.selected]: selected
    });

    return (
        <button className={buttonStyles} onClick={() => handleClick(value)} type="button">
            {text}
        </button>
    );
};

SelectableButton.styles = {
    normal: "nos-brandis__selectable-button__normal",
    selected: "nos-brandis__selectable-button__selected"
};

SelectableButton.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    selected: PropTypes.bool
};

SelectableButton.defaultProps = {
    selected: false
};

export default SelectableButton;
