import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FilterStrip, FilterSelector, Loading, Summary } from "../../common";

import ReturnsDashboardActions from "./redux/ReturnsDashboardActions";
import { RETURNS_DASHBOARD_FILTERS } from "../../utils/dashboard-config";
import { RETURNS_DASHBOARD_VIEWS, CHART_VIEW_SERVER_ENUMS } from "../../utils/chart-config";
import ChartsContainer from "../charts/ChartsContainer";
import VERBIAGE from "../../../utils/enums/verbiage";

const ReturnsDashboardContainer = props => {
    const {
        returns,
        setToDate,
        dashboard,
        setFromDate,
        getSummaryData,
        getFilterCategoryOptions,
        removeFilterFromStore,
        addFilterToStore,
        resetFilters,
        applyFilters,
        setSaleType,
        updateView
    } = props;

    const [isFilterStripExpanded, setIsFilterStripExpanded] = useState(true);

    const handleViewMenuClick = menu => updateView(CHART_VIEW_SERVER_ENUMS[menu]);

    const handleCancelClick = () => setIsFilterStripExpanded(!isFilterStripExpanded);

    const handleResetClick = () => resetFilters();

    const handleApplyClick = () => applyFilters();

    useEffect(() => {
        getSummaryData();
    }, [getSummaryData]);

    useEffect(() => {
        applyFilters();
    }, [applyFilters, returns.views, returns.filters]);

    const chartsContainerStyles = classnames(ReturnsDashboardContainer.styles.chartContainer, {
        [ReturnsDashboardContainer.styles.loaderContainer]: returns.isFetching
    });

    return (
        <div className={ReturnsDashboardContainer.styles.container}>
            <FilterStrip
              selectedDashboardStore={returns}
              isFilterStripExpanded={isFilterStripExpanded}
              setFilterStripToggleState={setIsFilterStripExpanded}
            />
            {isFilterStripExpanded && (
                <FilterSelector
                  setToDate={setToDate}
                  setSaleType={setSaleType}
                  setFromDate={setFromDate}
                  selectedDashboardStore={returns}
                  handleResetClick={handleResetClick}
                  handleApplyClick={handleApplyClick}
                  handleCancelClick={handleCancelClick}
                  viewMenu={RETURNS_DASHBOARD_VIEWS}
                  addFilterToStore={addFilterToStore}
                  selectedDashboard={dashboard.selected}
                  handleViewMenuClick={handleViewMenuClick}
                  removeFilterFromStore={removeFilterFromStore}
                  filterCategories={RETURNS_DASHBOARD_FILTERS}
                  getFilterCategoryOptions={getFilterCategoryOptions}
                />
            )}
            <Summary summary={returns.summary} dateRange={returns.dateRange} />
            {dashboard.selected.length !== 0 && (
                <div className={chartsContainerStyles}>
                    {returns.isFetching ? (
                        <Loading />
                    ) : (
                        (() =>
                            returns.views.length > 0 ? (
                                <ChartsContainer
                                  selectedDashboard={dashboard.selected}
                                  records={returns.records}
                                  views={returns.views}
                                />
                            ) : (
                                <div className={ReturnsDashboardContainer.styles.filterVerbiage}>
                                    {VERBIAGE.PROMPT.VIEWS}
                                </div>
                            ))()
                    )}
                </div>
            )}
        </div>
    );
};

ReturnsDashboardContainer.propTypes = {
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired
};

ReturnsDashboardContainer.defaultProps = {};

const mapStateToProps = state => ({
    returns: state.returns,
    dashboard: state.dashboard
});

const mapDispatchToProps = dispatch => ({
    setFromDate: payload => {
        dispatch(ReturnsDashboardActions.setFromDate(payload));
    },
    setToDate: payload => {
        dispatch(ReturnsDashboardActions.setToDate(payload));
    },
    getFilterCategoryOptions: category => {
        dispatch(ReturnsDashboardActions.getFilterCategoryOptions(category));
    },
    addFilterToStore: payload => {
        dispatch(ReturnsDashboardActions.addFilterToStore(payload));
    },
    removeFilterFromStore: payload => {
        dispatch(ReturnsDashboardActions.removeFilterFromStore(payload));
    },
    setSaleType: payload => {
        dispatch(ReturnsDashboardActions.setSaleType(payload));
    },
    updateView: payload => {
        dispatch(ReturnsDashboardActions.updateView(payload));
    },
    resetFilters: () => {
        dispatch(ReturnsDashboardActions.resetFilters());
    },
    applyFilters: () => {
        dispatch(ReturnsDashboardActions.applyFiltersAndFetchData());
    },
    getSummaryData: () => {
        dispatch(ReturnsDashboardActions.getSummaryData());
    }
});

ReturnsDashboardContainer.styles = {
    container: "nos-brandis__dashboard__common__container-main",
    chartContainer: "nos-brandis__dashboard__common__container-charts",
    loaderContainer: "nos-brandis__dashboard__common__container-loader",
    filterVerbiage: "nos-brandis__dashboard__common__container-filter-verbiage"
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReturnsDashboardContainer);
