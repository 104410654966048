import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "../styles/card.scss";
import { VERBIAGE } from "../../../utils/enums";
import { formatCurrency } from "../../utils/common-utils";
import { SUMMARY_CARD_TYPE } from "../../utils/dashboard-config";

const Card = props => {
    const { type, header, amount, units, customer } = props;

    const bottomContainerStyles = classnames(Card.styles.bottomContainer, {
        [Card.styles.current]: type === SUMMARY_CARD_TYPE.CURRENT_RANGE_SELECTED,
        [Card.styles.today]: type === SUMMARY_CARD_TYPE.TODAY,
        [Card.styles.monthToDate]: type === SUMMARY_CARD_TYPE.MTD,
        [Card.styles.yearToDate]: type === SUMMARY_CARD_TYPE.YTD,
        [Card.styles.previousYear]: type === SUMMARY_CARD_TYPE.LAST_YEAR
    });

    return (
        <div className={Card.styles.mainContainer}>
            <div className={Card.styles.topContainer}>
                <span className={Card.styles.headerText}>{header}</span>
                <span className={Card.styles.amountText}>
                    {`${VERBIAGE.CARD.LABELS.INR} ${formatCurrency(amount)}`}
                </span>
            </div>
            <div className={bottomContainerStyles}>
                <div className={Card.styles.bottomLeftContainer}>
                    <span className={Card.styles.unitCountText}>{units}</span>
                    <span className={Card.styles.unitLabel}>{VERBIAGE.CARD.LABELS.UNITS}</span>
                </div>
                <div className={Card.styles.bottomRightContainer}>
                    <span className={Card.styles.customerCountText}>{customer}</span>
                    <span className={Card.styles.customerLabel}>
                        {VERBIAGE.CARD.LABELS.CUSTOMERS}
                    </span>
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    type: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    amount: PropTypes.number,
    units: PropTypes.number,
    customer: PropTypes.number
};

Card.defaultProps = {
    amount: 0,
    units: 0,
    customer: 0
};

Card.styles = {
    mainContainer: "nos-brandis__card__container-main",
    topContainer: "nos-brandis__card__container-top",
    bottomContainer: "nos-brandis__card__container-bottom",
    headerText: "nos-brandis__card__text-header",
    amountText: "nos-brandis__card__text-amount",
    bottomLeftContainer: "nos-brandis__card__container-bottom-left",
    bottomRightContainer: "nos-brandis__card__container-bottom-right",
    unitCountText: "nos-brandis__card__text-unit-count",
    unitLabel: "nos-brandis__card__text-unit-label",
    customerCountText: "nos-brandis__card__text-customer-count",
    customerLabel: "nos-brandis__card__text-customer-label",
    current: "nos-brandis__card__container-bottom-current",
    today: "nos-brandis__card__container-bottom-today",
    monthToDate: "nos-brandis__card__container-bottom-monthly",
    yearToDate: "nos-brandis__card__container-bottom-yearly",
    previousYear: "nos-brandis__card__container-bottom-last-year"
};

export default Card;

// cardContainer: "nos-ds__card-container",
//     cardStyling: "nos-ds__card-container",
//     logoStyling: "nos-ds__card-logo",
//     primaryData: "nos-ds__card-primary-data",
//     secondaryDataLeft: "nos-ds__card-secondary-data-left",
//     secondaryDataCenter: "nos-ds__card-secondary-data-center",
//     secondaryDataRight: "nos-ds__card-secondary-data-right",
//     secondaryContainer: "nos-ds__card-secondary-container"
// <h2 className={Card.styles.greenColor}>{label}</h2>
// <img alt="icon" src={image} className={Card.styles.logoStyling} />
// <h3 className={Card.styles.primaryData}>{primaryData}</h3>
// <div className={Card.styles.secondaryContainer}>
//   <h4 className={Card.styles.secondaryDataLeft}>{secondaryDataLeft}</h4>
//   <h4 className={Card.styles.secondaryDataCenter}>{secondaryDataCenter}</h4>
//   <h4 className={Card.styles.secondaryDataRight}>{secondaryDataRight}</h4>
// </div>

// <div className={Card.styles.cardContainer}>
//   <h2 className={Card.styles.greenColor}>
//     {ENUMS.CARD.LABEL}
//   </h2>
//   <img alt="icon" src={ENUMS.CARD.ICON_URL} className={Card.styles.logoStyling} />
//   <h3 className={Card.styles.primaryData}>
//     {ENUMS.CARD.PRIMARY_DATA}
//   </h3>
//   <div className={Card.styles.secondaryContainer}>
//     <h4 className={Card.styles.secondaryDataLeft}>
//       {ENUMS.CARD.SECONDARY_DATA_LEFT}
//     </h4>
//     <h4 className={Card.styles.secondaryDataCenter}>
//       {ENUMS.CARD.SECONDARY_DATA_CENTER}
//     </h4>
//     <h4 className={Card.styles.secondaryDataRight}>
//       {ENUMS.CARD.SECONDARY_DATA_RIGHT}
//     </h4>
//   </div>
// </div>
