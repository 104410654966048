import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import InventoryDashboard from "./features/inventory/InventoryDashboardContainer";
import ReturnsDashboard from "./features/returns/ReturnsDashboardContainer";
import SalesDashboard from "./features/sales/SalesDashboardContainer";
import { DASHBOARD_PATH } from "./utils/dashboard-config";

const DashboardRoutes = () => {
    return (
        <Fragment>
            <Route exact path="/dashboard/" component={InventoryDashboard} />
            <Route path={DASHBOARD_PATH.INVENTORY} component={InventoryDashboard} />
            <Route path={DASHBOARD_PATH.RETURNS} component={ReturnsDashboard} />
            <Route path={DASHBOARD_PATH.SALES} component={SalesDashboard} />
        </Fragment>
    );
};

export default DashboardRoutes;
