import { combineReducers } from "redux";

import authentication from "./authentication/redux/AuthenticationReducer";

import dashboard from "./dashboard/redux/DashboardReducer";
import inventory from "./dashboard/features/inventory/redux/InventoryDashboardReducer";
import returns from "./dashboard/features/returns/redux/ReturnsDashboardReducer";
import sales from "./dashboard/features/sales/redux/SalesDashboardReducer";

export default combineReducers({
    authentication,
    dashboard,
    inventory,
    returns,
    sales
});
