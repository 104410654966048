import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FILTER_LABELS } from "../../utils/dashboard-config";

import "../styles/date-filter.scss";

// TODO: Add date range validation
const DateFilter = props => {
    const { setToDate, setFromDate, dateRange } = props;

    const fromDate = new Date(Date.parse(moment(dateRange.from, "YYYYMMDD").format("YYYY-MM-DD")));

    const toDate = new Date(Date.parse(moment(dateRange.to, "YYYYMMDD").format("YYYY-MM-DD")));

    const handleChangeFromDate = value => setFromDate(moment(value).format("YYYYMMDD"));

    const handleChangeToDate = value => setToDate(moment(value).format("YYYYMMDD"));

    return (
        <div className={DateFilter.styles.mainContaier}>
            <span className={DateFilter.styles.label}>{FILTER_LABELS.DATE}</span>
            <div className={DateFilter.styles.pickerContainer}>
                <span className={DateFilter.styles.rangeLabel}>{FILTER_LABELS.FROM}</span>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={fromDate}
                  onChange={handleChangeFromDate}
                  maxDate={new Date()}
                  className={DateFilter.styles.picker}
                />
            </div>
            <div className={DateFilter.styles.pickerContainer}>
                <span className={DateFilter.styles.rangeLabel}>{FILTER_LABELS.TO}</span>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={toDate}
                  onChange={handleChangeToDate}
                  minDate={fromDate}
                  maxDate={new Date()}
                  className={DateFilter.styles.picker}
                />
            </div>
        </div>
    );
};

DateFilter.styles = {
    pickerContainer: "nos-brandis__date-filter__container-picker",
    mainContaier: "nos-brandis__date-filter__container-main",
    rangeLabel: "nos-brandis__date-filter__label-range",
    label: "nos-brandis__date-filter__label-category",
    picker: "nos-brandis__date-filter__picker"
};

DateFilter.propTypes = {
    setToDate: PropTypes.func.isRequired,
    setFromDate: PropTypes.func.isRequired,
    dateRange: PropTypes.object.isRequired
};

export default DateFilter;
