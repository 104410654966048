import React from "react";
import PropTypes from "prop-types";

import ChartWrapper from "./components/ChartWrapper";
import { CHART_TYPE, CHART_VIEW_MAPPER } from "../../utils/chart-config";
import {
    formatDataForPieChart,
    formatDataForBarChart,
    formatDataForStackedBarChart,
    formatDataForGroupedBarChart
} from "../../utils/chart-utils";

const ChartsContainer = props => {
    const { views, selectedDashboard, records } = props;

    const formatData = view => {
        switch (CHART_VIEW_MAPPER[view]) {
            case CHART_TYPE.GROUPED_BAR:
                return formatDataForGroupedBarChart(records, view, CHART_VIEW_MAPPER[view]);
            case CHART_TYPE.BAR:
                return formatDataForBarChart(records, view, CHART_VIEW_MAPPER[view]);
            case CHART_TYPE.PIE:
                return formatDataForPieChart(records, view, CHART_VIEW_MAPPER[view]);
            case CHART_TYPE.STACKED_BAR:
                return formatDataForStackedBarChart(records, view, CHART_VIEW_MAPPER[view]);
            default:
                return {
                    data: [],
                    legendItems: []
                };
        }
    };

    return views.map(view => {
        const formattedData = formatData(view);
        return (
            <ChartWrapper
              view={view}
              dashboardType={selectedDashboard[0].value}
              data={formattedData.data}
              legendItems={formattedData.legendItems}
            />
        );
    });
};

ChartsContainer.propTypes = {
    views: PropTypes.array,
    records: PropTypes.array,
    selectedDashboard: PropTypes.object.isRequired
};

ChartsContainer.defaultProps = {
    views: [],
    records: []
};

export default ChartsContainer;

// const formatter = {
//     [CHART_TYPE.BAR]: formatDataForBarChart(records, view, CHART_VIEW_MAPPER[view]),
//     [CHART_TYPE.STACKED_BAR]: formatDataForStackedBarChart(
//         records,
//         view,
//         CHART_VIEW_MAPPER[view],
//     ),
//     [CHART_TYPE.PIE]: formatDataForPieChart(records, view, CHART_VIEW_MAPPER[view]),
//     [CHART_TYPE.GROUPED_BAR]:
// formatDataForGroupedBarChart(
//         records,
//         view,
//         CHART_VIEW_MAPPER[view],
//     )
// };
// return formatter[CHART_VIEW_MAPPER[view]];
