import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./Reducer";

let middlewares = [thunk];

if (process.env.NODE_ENV === "development")
    // Or, `process.env.NODE_ENV !== 'production'`
    // Only runs in development and will be stripped from production build.
    middlewares = [...middlewares, logger];

export default createStore(reducer, applyMiddleware(...middlewares));
