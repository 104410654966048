const CLIENTS = {
    BRANDIS: {
        LOGO:
            "https://firebasestorage.googleapis.com/v0/b/" +
            "brandis-4d398.appspot.com/o/logo1.png?" +
            "alt=media&token=33229766-2d7a-41ca-8e59-fdacc4cd4829",
        DISPLAY_NAME: "Brandis"
    }
};

export default CLIENTS;
