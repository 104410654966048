import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/fa/search";
import { FILTER_LABELS } from "../../utils/dashboard-config";
import Checkbox from "./Checkbox";
import { isValuePresentInFilter, textFilteredOptions } from "../../utils/filters";
import "../styles/multi-select-filter.scss";
import { VERBIAGE } from "../../../utils/enums";

const MultiSelectFilter = props => {
    const {
        filters,
        category,
        getFilterCategoryOptions,
        filterOptions,
        addFilterToStore,
        removeFilterFromStore
    } = props;

    const [filterCategoryText, setFilterCategoryText] = useState("");

    const handleTextChange = value => setFilterCategoryText(value);

    // Fetch each category filter option on mount
    useEffect(() => {
        getFilterCategoryOptions(category);
    }, [category, getFilterCategoryOptions]);

    return (
        <div className={MultiSelectFilter.styles.rootContainer}>
            <div className={MultiSelectFilter.styles.verticalDivider} />
            <div className={MultiSelectFilter.styles.mainContainer}>
                <div className={MultiSelectFilter.styles.categoryLabel}>
                    {FILTER_LABELS[category]}
                </div>
                <div className={MultiSelectFilter.styles.selectCardContainer}>
                    <div className={MultiSelectFilter.styles.checkboxInputContainer}>
                        <Icon icon={search} size={14} />
                        <input
                          onChange={event => handleTextChange(event.target.value)}
                          className={MultiSelectFilter.styles.input}
                          placeholder={VERBIAGE.PLACEHOLDER.SEARCH}
                          value={filterCategoryText}
                        />
                    </div>
                    <div className={MultiSelectFilter.styles.horizontalDivider} />
                    <div className={MultiSelectFilter.styles.optionsContainer}>
                        {Object.keys(filterOptions).length !== 0 &&
                            filterOptions[category] &&
                            textFilteredOptions(filterOptions[category], filterCategoryText).map(
                                item => (
                                    <Checkbox
                                      value={item.code}
                                      category={category}
                                      label={item.description}
                                      addFilterToStore={addFilterToStore}
                                      removeFilterFromStore={removeFilterFromStore}
                                      checked={isValuePresentInFilter(
                                            filters,
                                            item.code,
                                            category,
                                        )}
                                    />
                                ),
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

MultiSelectFilter.styles = {
    input: "nos-brandis__multi-select__input",
    verticalDivider: "nos-brandis__multi-select__divider-vertical",
    horizontalDivider: "nos-brandis__multi-select__divider-horizontal",
    rootContainer: "nos-brandis__multi-select__container-root",
    mainContainer: "nos-brandis__multi-select__container-main",
    categoryLabel: "nos-brandis__multi-select__label-category",
    optionsContainer: "nos-brandis__multi-select__container-options",
    selectCardContainer: "nos-brandis__multi-select__container-select-card",
    checkboxInputContainer: "nos-brandis__multi-select__container-checkbox-input"
};

MultiSelectFilter.propTypes = {
    filters: PropTypes.array.isRequired,
    category: PropTypes.string.isRequired,
    filterOptions: PropTypes.object.isRequired,
    getFilterCategoryOptions: PropTypes.func.isRequired
};

export default MultiSelectFilter;
