import DashboardActionTypes from "./DashboardActionTypes";

const initialState = {
    selected: []
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DashboardActionTypes.SET_CURRENT_DASHBOARD:
            return {
                ...state,
                selected: [...action.payload]
            };
        default:
            return state;
    }
};

export default dashboardReducer;
