import InventoryDashboardActionTypes from "./InventoryDashboardActionTypes";
import { DEFAULT_DATE_RANGE } from "../../../utils/dashboard-config";
import VERBIAGE from "../../../../utils/enums/verbiage";

const initialState = {
    isFetching: false,
    fetchComplete: true,
    fetchError: VERBIAGE.EMPTY_STRING,
    filters: [],
    records: [],
    dateRange: {
        from: DEFAULT_DATE_RANGE.FROM,
        to: DEFAULT_DATE_RANGE.TO
    },
    views: ["category", "size"],
    filterOptions: {}
};

const inventoryDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case InventoryDashboardActionTypes.BEGIN_FETCH:
            return {
                ...state,
                isFetching: true,
                fetchComplete: false,
                fetchError: VERBIAGE.EMPTY_STRING
            };
        case InventoryDashboardActionTypes.FETCH_COMPLETE:
            return {
                ...state,
                isFetching: false,
                fetchComplete: true,
                fetchError: VERBIAGE.EMPTY_STRING
            };
        case InventoryDashboardActionTypes.FETCH_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchComplete: true,
                fetchError: action.payload
            };
        case InventoryDashboardActionTypes.SET_FILTERED_DATA:
            return {
                ...state,
                records: action.payload
            };
        case InventoryDashboardActionTypes.SET_FROM_DATE:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    from: action.payload
                }
            };
        case InventoryDashboardActionTypes.SET_TO_DATE:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    to: action.payload
                }
            };
        case InventoryDashboardActionTypes.SET_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    ...action.payload
                }
            };
        case InventoryDashboardActionTypes.ADD_FILTER:
            return {
                ...state,
                filters: action.payload
            };
        case InventoryDashboardActionTypes.REMOVE_FILTER:
            return {
                ...state,
                filters: action.payload
            };
        case InventoryDashboardActionTypes.ADD_VIEW:
            return {
                ...state,
                views: [...state.views, action.payload]
            };
        case InventoryDashboardActionTypes.REMOVE_VIEW:
            return {
                ...state,
                views: state.views.filter(item => item !== action.payload)
            };
        case InventoryDashboardActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: [],
                dateRange: {
                    from: DEFAULT_DATE_RANGE.FROM,
                    to: DEFAULT_DATE_RANGE.TO
                },
                views: []
            };
        default:
            return state;
    }
};

export default inventoryDashboardReducer;
