import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import PrivateRoutes from "./PrivateRoutes";
import Dashboard from "../dashboard/Dashboard";
import Authentication from "../authentication/Authentication";

const history = createBrowserHistory();

const Routes = () => {
    return (
        <Router history={history}>
            <div>
                <Route exact path="/" component={Authentication} />
                <PrivateRoutes path="/dashboard" component={Dashboard} />
            </div>
        </Router>
    );
};

export default Routes;
