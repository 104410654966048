import { DASHBOARD_TYPE } from "./dashboard-config";

const getSliceIndex = ([dashboard]) => {
    if (dashboard && dashboard.value === DASHBOARD_TYPE.INVENTORY) return 1;
    return 2;
};

const dummyFunction = () => {};

export { getSliceIndex, dummyFunction };
