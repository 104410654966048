const CHART_TYPE = {
    BAR: "BAR",
    STACKED_BAR: "STACKED_BAR",
    PIE: "PIE",
    GROUPED_BAR: "GROUPED_BAR"
};

const CHART_VIEWS = {
    ACCOUNT: "ACCOUNT",
    CATEGORY: "CATEGORY",
    CHANNEL: "CHANNEL",
    CITY: "CITY",
    COLOR: "COLOR",
    DISCOUNT: "DISCOUNT",
    FABRIC: "FABRIC",
    GENDER: "GENDER",
    PRINTING: "PRINTING",
    PRODUCT: "PRODUCT",
    ROS: "ROS",
    STATE: "STATE",
    SEASON: "SEASON",
    SIZE: "SIZE",
    SELLER_CLASSIFICATION: "SELLER_CLASSIFICATION", // This is best seller
    WAREHOUSE: "WAREHOUSE"
};

const CHART_VIEW_SERVER_ENUMS = {
    ACCOUNT: "account",
    CATEGORY: "category",
    CHANNEL: "channel",
    CITY: "city",
    COLOR: "colorgroup",
    DISCOUNT: "discount",
    FABRIC: "fabric",
    GENDER: "gender",
    PRINTING: "printingType",
    PRODUCT: "productid",
    ROS: "rateOfSale",
    STATE: "state",
    SEASON: "season",
    SIZE: "size",
    SELLER_CLASSIFICATION: "bestSeller", // This is best seller
    WAREHOUSE: "warehouselocation"
};

const CHART_VIEW_LABEL = {
    ACCOUNT: "Account",
    CATEGORY: "Category",
    CHANNEL: "Channel",
    CITY: "City",
    COLOR: "Color",
    DISCOUNT: "Discount",
    FABRIC: "Fabric",
    GENDER: "Gender",
    PRINTING: "Printing",
    PRODUCT: "Product",
    ROS: "ROS",
    STATE: "State",
    SEASON: "Season",
    SIZE: "Size",
    SELLER_CLASSIFICATION: "Best Seller", // This is best seller
    WAREHOUSE: "Warehouse"
};

const CHART_CONTAINER_LABEL = {
    [CHART_VIEW_SERVER_ENUMS.ACCOUNT]: CHART_VIEW_LABEL.ACCOUNT,
    [CHART_VIEW_SERVER_ENUMS.CATEGORY]: CHART_VIEW_LABEL.CATEGORY,
    [CHART_VIEW_SERVER_ENUMS.CHANNEL]: CHART_VIEW_LABEL.CHANNEL,
    [CHART_VIEW_SERVER_ENUMS.CITY]: CHART_VIEW_LABEL.CITY,
    [CHART_VIEW_SERVER_ENUMS.COLOR]: CHART_VIEW_LABEL.COLOR,
    [CHART_VIEW_SERVER_ENUMS.DISCOUNT]: CHART_VIEW_LABEL.DISCOUNT,
    [CHART_VIEW_SERVER_ENUMS.FABRIC]: CHART_VIEW_LABEL.FABRIC,
    [CHART_VIEW_SERVER_ENUMS.GENDER]: CHART_VIEW_LABEL.GENDER,
    [CHART_VIEW_SERVER_ENUMS.PRINTING]: CHART_VIEW_LABEL.PRINTING,
    [CHART_VIEW_SERVER_ENUMS.PRODUCT]: CHART_VIEW_LABEL.PRODUCT,
    [CHART_VIEW_SERVER_ENUMS.ROS]: CHART_VIEW_LABEL.ROS,
    [CHART_VIEW_SERVER_ENUMS.STATE]: CHART_VIEW_LABEL.STATE,
    [CHART_VIEW_SERVER_ENUMS.SEASON]: CHART_VIEW_LABEL.SEASON,
    [CHART_VIEW_SERVER_ENUMS.SIZE]: CHART_VIEW_LABEL.SIZE,
    [CHART_VIEW_SERVER_ENUMS.SELLER_CLASSIFICATION]: CHART_VIEW_LABEL.SELLER_CLASSIFICATION,
    [CHART_VIEW_SERVER_ENUMS.WAREHOUSE]: CHART_VIEW_LABEL.WAREHOUSE
};

const CHART_VIEW_MAPPER = {
    [CHART_VIEW_SERVER_ENUMS.ACCOUNT]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.CATEGORY]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.CHANNEL]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.CITY]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.COLOR]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.DISCOUNT]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.FABRIC]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.GENDER]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.PRINTING]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.PRODUCT]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.ROS]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.STATE]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.SEASON]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.SIZE]: CHART_TYPE.STACKED_BAR,
    [CHART_VIEW_SERVER_ENUMS.SELLER_CLASSIFICATION]: CHART_TYPE.GROUPED_BAR,
    [CHART_VIEW_SERVER_ENUMS.WAREHOUSE]: CHART_TYPE.STACKED_BAR
};

const INVENTORY_DASHBOARD_VIEWS = [
    CHART_VIEWS.CATEGORY,
    CHART_VIEWS.SELLER_CLASSIFICATION,
    CHART_VIEWS.COLOR,
    CHART_VIEWS.DISCOUNT,
    CHART_VIEWS.ROS,
    CHART_VIEWS.SEASON,
    CHART_VIEWS.WAREHOUSE,
    CHART_VIEWS.SIZE
];

const SALES_DASHBOARD_VIEWS = [
    CHART_VIEWS.ACCOUNT,
    CHART_VIEWS.CATEGORY,
    CHART_VIEWS.COLOR,
    CHART_VIEWS.PRODUCT,
    CHART_VIEWS.SEASON,
    CHART_VIEWS.FABRIC,
    CHART_VIEWS.PRINTING,
    CHART_VIEWS.GENDER,
    CHART_VIEWS.CITY,
    CHART_VIEWS.STATE
];

const RETURNS_DASHBOARD_VIEWS = [
    CHART_VIEWS.CATEGORY,
    CHART_VIEWS.COLOR,
    CHART_VIEWS.PRODUCT,
    CHART_VIEWS.SEASON,
    CHART_VIEWS.FABRIC,
    CHART_VIEWS.PRINTING,
    CHART_VIEWS.GENDER,
    // CHART_VIEWS.CITY,
    CHART_VIEWS.STATE
];

export {
    CHART_TYPE,
    CHART_VIEWS,
    CHART_VIEW_SERVER_ENUMS,
    INVENTORY_DASHBOARD_VIEWS,
    SALES_DASHBOARD_VIEWS,
    RETURNS_DASHBOARD_VIEWS,
    CHART_VIEW_LABEL,
    CHART_CONTAINER_LABEL,
    CHART_VIEW_MAPPER
};
