import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "../styles/filter-pills.scss";
import VERBIAGE from "../../../utils/enums/verbiage";

const FilterPills = props => {
    const { name, values, badge } = props;

    const containerStyles = classnames(FilterPills.styles.mainContainer, {
        [FilterPills.styles.normalContainer]: badge,
        [FilterPills.styles.badgedContainer]: !badge
    });
    return (
        values.length > 0 && (
            <div className={containerStyles}>
                <div className={FilterPills.styles.pillText}>
                    {`${name} : ${values.toString()}`}
                </div>
                {badge && (
                    <div className={FilterPills.styles.badgeContainer}>
                        <span className={FilterPills.styles.badge}>{`+${badge}`}</span>
                    </div>
                )}
            </div>
        )
    );
};

FilterPills.styles = {
    mainContainer: "nos-brandis__filter-pills__container-main",
    normalContainer: "nos-brandis__filter-pills__container-normal",
    badgedContainer: "nos-brandis__filter-pills__container-badged",
    pillText: "nos-brandis__filter-pills__text",
    badgeContainer: "nos-brandis__filter-pills__container-badge",
    badge: "nos-brandis__filter-pills__badge"
};

FilterPills.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array,
    badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

FilterPills.defaultProps = {
    name: VERBIAGE.EMPTY_STRING,
    values: []
};

export default FilterPills;
