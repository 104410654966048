import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown-select";
import { SALE_TYPE_OPTIONS, SALE_TYPE_DEFAULT_OPTION } from "../../utils/dashboard-config";

import "../styles/sale-type-filter.scss";

const SaleTypeFilter = props => {
    const { values, setSaleType } = props;
    return (
        <div className={SaleTypeFilter.style.mainContainer}>
            <span className={SaleTypeFilter.style.label}>Sale Type</span>
            <Dropdown
              onChange={value => setSaleType(value)}
              options={SALE_TYPE_OPTIONS}
              values={values}
            />
        </div>
    );
};

SaleTypeFilter.propTypes = {
    setSaleType: PropTypes.func.isRequired,
    values: PropTypes.array
};

SaleTypeFilter.defaultProps = {
    values: SALE_TYPE_DEFAULT_OPTION
};

SaleTypeFilter.style = {
    mainContainer: "nos-brandis__sale-type-filter__container-main",
    label: "nos-brandis__sale-type-filter__label-category"
};

export default SaleTypeFilter;
