import DashboardActionTypes from "./DashboardActionTypes";

const DashboardActions = {
    setDashboard: payload => ({
        type: DashboardActionTypes.SET_CURRENT_DASHBOARD,
        payload
    })
};

export default DashboardActions;
