const VERBIAGE = {
    EMPTY_STRING: "",
    HEADER: {
        CURRENT_DASHBOARD: "Current Dashboard:"
    },
    BUTTONS: {
        LOGOUT: "Logout",
        REFRESH: "Refresh",
        CANCEL: "Cancel",
        RESET: "Reset All Filters",
        APPLY: "Apply All Filters"
    },
    PLACEHOLDER: {
        DROPDOWN: "Select a dashhboard",
        SEARCH: "Search..."
    },
    LABELS: {
        FILTER: "Filters :",
        VIEWS: "Views"
    },
    DAHSBOARD: {
        INVENTORY: "Inventory",
        SALES: "Sales",
        RETURNS: "Returns"
    },
    PROMPT: {
        VIEWS:
            "No views selected." +
            "Please select a view from above to visualize your data preferences."
    },
    CARD: {
        LABELS: {
            CURRENT_RANGE_SELECTED: "Current Date Range",
            TODAY: "Today",
            MTD: "Month Till Date",
            YTD: "Year Till Date",
            LAST_YEAR: "Last Year",
            UNITS: "Units",
            CUSTOMERS: "Customers",
            INR: "Rs. "
        }
    },
    CHARTS: {
        FETCH_FAILURE:
            "We could not find any data from the server.\n" +
            " Please try again after some time or contact your system administrator."
    }
};

export default VERBIAGE;
