const DASHBOARD_TYPE = {
    SALES: "SALES",
    RETURNS: "RETURNS",
    INVENTORY: "INVENTORY"
};

const DASHBOARD_PATH = {
    SALES: "/dashboard/sales",
    RETURNS: "/dashboard/returns",
    INVENTORY: "/dashboard/inventory"
};

const DASHBOARD_MENU = [
    {
        label: "Inventory Dashboard",
        value: DASHBOARD_TYPE.INVENTORY,
        path: DASHBOARD_PATH.INVENTORY
    },
    {
        label: "Sales Dashboard",
        value: DASHBOARD_TYPE.SALES,
        path: DASHBOARD_PATH.SALES
    },
    {
        label: "Returns Dashboard",
        value: DASHBOARD_TYPE.RETURNS,
        path: DASHBOARD_PATH.RETURNS
    }
];

const DEFAULT_OPTION = [
    {
        label: "Inventory Dashboard",
        value: DASHBOARD_TYPE.INVENTORY,
        path: DASHBOARD_PATH.INVENTORY
    }
];

const SALE_TYPE_OPTIONS = [
    {
        label: "Primary",
        value: "primary"
    },
    {
        label: "Secondary",
        value: "secondary"
    }
];

const SALE_TYPE_DEFAULT_OPTION = [
    {
        label: "Primary",
        value: "primary"
    }
];

const FILTER_CATEGORIES = {
    DATE: "DATE",
    SELLER_CLASSIFICATION: "SELLER_CLASSIFICATION",
    SEASON: "SEASON",
    CATEGORY: "CATEGORY",
    CHANNEL: "CHANNEL",
    STATE: "STATE",
    CITY: "CITY",
    SALE_TYPE: "SALE_TYPE",
    PRODUCT: "PRODUCT"
};

const FILTER_CATEGORIES_SERVER_ENUM = {
    SELLER_CLASSIFICATION: "bestSeller",
    SEASON: "season",
    CATEGORY: "category",
    CHANNEL: "channel",
    STATE: "state",
    CITY: "city"
};

const FILTER_STRIP_LABEL = {
    DATE: "Date",
    SALETYPE: "Sale Type",
    [FILTER_CATEGORIES_SERVER_ENUM.SELLER_CLASSIFICATION]: "Best Seller",
    [FILTER_CATEGORIES_SERVER_ENUM.SEASON]: "Seasons",
    [FILTER_CATEGORIES_SERVER_ENUM.CATEGORY]: "Category",
    [FILTER_CATEGORIES_SERVER_ENUM.CHANNEL]: "Channel",
    [FILTER_CATEGORIES_SERVER_ENUM.STATE]: "State",
    [FILTER_CATEGORIES_SERVER_ENUM.CITY]: "City"
};

const INVENTORY_DASHBOARD_FILTERS = [
    FILTER_CATEGORIES.DATE,
    FILTER_CATEGORIES.SELLER_CLASSIFICATION,
    FILTER_CATEGORIES.SEASON,
    FILTER_CATEGORIES.CITY,
    FILTER_CATEGORIES.STATE
];

const SALES_DASHBOARD_FILTERS = [
    FILTER_CATEGORIES.DATE,
    FILTER_CATEGORIES.SALE_TYPE,
    FILTER_CATEGORIES.CATEGORY,
    FILTER_CATEGORIES.CHANNEL,
    FILTER_CATEGORIES.CITY,
    FILTER_CATEGORIES.STATE
];

const RETURNS_DASHBOARD_FILTERS = [
    FILTER_CATEGORIES.DATE,
    FILTER_CATEGORIES.SALE_TYPE,
    FILTER_CATEGORIES.CATEGORY,
    FILTER_CATEGORIES.CHANNEL,
    FILTER_CATEGORIES.CITY,
    FILTER_CATEGORIES.STATE
];

const FILTER_LABELS = {
    DATE: "Date Range",
    SELLER_CLASSIFICATION: "Seller Classification",
    SEASON: "Season",
    CATEGORY: "Category",
    CHANNEL: "Channel",
    STATE: "State",
    CITY: "City",
    SALE_TYPE: "Sale Type",
    FROM: "From:",
    TO: "To:",
    PRODUCT: "Product"
};

const FILTER_CHECKBOX_COLOR = "#4287f5";

const DEFAULT_DATE_RANGE = {
    FROM: "20180101",
    TO: "20190816"
};

const DEFAULT_PRODUCT_ICON =
    "https://cdn2.iconfinder.com/data/" +
    "icons/glyphie-1/40/T-shirt_shirt_clothing_dress_cloth_tank_top-512.png";

const SUMMARY_CARD_TYPE = {
    CURRENT_RANGE_SELECTED: "CURRENT_RANGE_SELECTED",
    TODAY: "TODAY",
    MTD: "MTD",
    YTD: "YTD",
    LAST_YEAR: "LAST_YEAR"
};

export {
    FILTER_LABELS,
    DASHBOARD_TYPE,
    DASHBOARD_MENU,
    DASHBOARD_PATH,
    DEFAULT_OPTION,
    SUMMARY_CARD_TYPE,
    FILTER_CATEGORIES,
    DEFAULT_DATE_RANGE,
    DEFAULT_PRODUCT_ICON,
    SALES_DASHBOARD_FILTERS,
    RETURNS_DASHBOARD_FILTERS,
    INVENTORY_DASHBOARD_FILTERS,
    FILTER_STRIP_LABEL,
    SALE_TYPE_OPTIONS,
    FILTER_CHECKBOX_COLOR,
    SALE_TYPE_DEFAULT_OPTION,
    FILTER_CATEGORIES_SERVER_ENUM
};
