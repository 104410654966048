import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "./Card";
import "../styles/summary.scss";
import { SUMMARY_CARD_TYPE } from "../../utils/dashboard-config";
import VERBIAGE from "../../../utils/enums/verbiage";

const Summary = props => {
    const { summary, dateRange } = props;

    const fromDate = moment(dateRange.from).format("DD MMM YYYY");

    const toDate = moment(dateRange.to).format("DD MMM YYYY");

    const dateRangeText = `From ${fromDate} to ${toDate}`;

    return (
        <div className={Summary.styles.mainContainer}>
            {Object.keys(summary).length !== 0 && (
                <Fragment>
                    <Card
                      type={SUMMARY_CARD_TYPE.CURRENT_RANGE_SELECTED}
                      header={dateRangeText}
                      amount={summary.current.totalamount}
                      units={summary.current.totalunits}
                    />
                    <Card
                      type={SUMMARY_CARD_TYPE.TODAY}
                      header={VERBIAGE.CARD.LABELS[SUMMARY_CARD_TYPE.TODAY]}
                      amount={summary.daily.totalamount}
                      units={summary.daily.totalunits}
                    />
                    <Card
                      type={SUMMARY_CARD_TYPE.MTD}
                      header={VERBIAGE.CARD.LABELS[SUMMARY_CARD_TYPE.MTD]}
                      amount={summary.mtd.totalamount}
                      units={summary.mtd.totalunits}
                    />
                    <Card
                      type={SUMMARY_CARD_TYPE.YTD}
                      header={VERBIAGE.CARD.LABELS[SUMMARY_CARD_TYPE.YTD]}
                      amount={summary.ytd.totalamount}
                      units={summary.ytd.totalunits}
                    />
                    <Card
                      type={SUMMARY_CARD_TYPE.LAST_YEAR}
                      header={VERBIAGE.CARD.LABELS[SUMMARY_CARD_TYPE.LAST_YEAR]}
                      amount={summary.prevyear.totalamount}
                      units={summary.prevyear.totalunits}
                    />
                </Fragment>
            )}
        </div>
    );
};

Summary.styles = {
    mainContainer: "nos-brandis__summary__container-main"
};

Summary.propTypes = {
    summary: PropTypes.object.isRequired,
    dateRange: PropTypes.object.isRequired
};

export default Summary;
