import React from "react";
import PropTypes from "prop-types";
import {
    RadialChart,
    XYPlot,
    VerticalBarSeries,
    XAxis,
    YAxis,
    HorizontalGridLines,
    DiscreteColorLegend
} from "react-vis";
import VERBIAGE from "../../../../utils/enums/verbiage";
import { CHART_CONTAINER_LABEL, CHART_TYPE, CHART_VIEW_MAPPER } from "../../../utils/chart-config";
import "../styles/chart-wrapper.scss";

const ChartWrapper = props => {
    const { view, dashboardType, data, legendItems } = props;

    const renderPieChart = () => (
        <RadialChart
          labelsRadiusMultiplier={1.1}
          labelsStyle={{ fontSize: 10 }}
          showLabels
          width={300}
          height={300}
          data={data}
        />
    );

    const renderStackedBarChart = () => {
        return data.length === 0 ? (
            <div className={ChartWrapper.styles.errorMessageContainer}>
                {VERBIAGE.CHARTS.FETCH_FAILURE}
            </div>
        ) : (
            <div className={ChartWrapper.styles.chartContainer}>
                <XYPlot stackBy="y" showLabels width={525} height={300} xType="ordinal">
                    <XAxis />
                    <YAxis left={10} />
                    <HorizontalGridLines />
                    {data.map(dataStack => (
                        <VerticalBarSeries cluster="stack 1" data={dataStack} />
                    ))}
                </XYPlot>
                <DiscreteColorLegend height={280} width={200} items={legendItems} />
            </div>
        );
    };

    const renderBarChart = () => {
        return (
            <XYPlot showLabels width={525} height={300}>
                <XAxis />
                <YAxis />
                <HorizontalGridLines />
                <VerticalBarSeries data={data} />
            </XYPlot>
        );
    };

    const renderGroupedBarChart = () => {
        return data.length === 0 ? (
            <div className={ChartWrapper.styles.errorMessageContainer}>
                {VERBIAGE.CHARTS.FETCH_FAILURE}
            </div>
        ) : (
            <div className={ChartWrapper.styles.chartContainer}>
                <XYPlot showLabels width={525} height={300} xType="ordinal">
                    <XAxis />
                    <YAxis left={10} />
                    <HorizontalGridLines />
                    {data.map(dataStack => (
                        <VerticalBarSeries data={dataStack} />
                    ))}
                </XYPlot>
                <DiscreteColorLegend height={280} width={200} items={legendItems} />
            </div>
        );
    };

    const renderChart = selectedView => {
        const chart = {
            [CHART_TYPE.BAR]: () => renderBarChart(),
            [CHART_TYPE.STACKED_BAR]: () => renderStackedBarChart(),
            [CHART_TYPE.PIE]: () => renderPieChart(),
            [CHART_TYPE.GROUPED_BAR]: () => renderGroupedBarChart()
        };

        return chart[CHART_VIEW_MAPPER[selectedView]]();
    };
    return (
        <div className={ChartWrapper.styles.mainContainer}>
            <div className={ChartWrapper.styles.label}>
                {`${VERBIAGE.DAHSBOARD[dashboardType]} by ${CHART_CONTAINER_LABEL[view]}`}
            </div>
            <div className={ChartWrapper.styles.chartContainer}>{renderChart(view)}</div>
        </div>
    );
};

ChartWrapper.styles = {
    mainContainer: "nos-brandis__chart-wrapper__container-main",
    chartContainer: "nos-brandis__chart-wrapper__container-chart",
    errorMessageContainer: "nos-brandis__chart-wrapper__container-error-message",
    label: "nos-brandis__chart-wrapper__label"
};

ChartWrapper.propTypes = {
    view: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    dashboardType: PropTypes.string
};

ChartWrapper.defaultProps = {
    dashboardType: VERBIAGE.EMPTY_STRING
};

export default ChartWrapper;
