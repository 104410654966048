const currencyFormatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3
});

const formatCurrency = amount => {
    return currencyFormatter.format(amount);
};

const dummyFunction = () => {};

export { formatCurrency, dummyFunction };
