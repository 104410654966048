import React from "react";
import ICONS from "../../../utils/enums/icon-data";

import "../styles/loading.scss";

const Loading = () => {
    return <img alt="loading" src={ICONS.LOADING} className={Loading.styles.image} />;
};

Loading.styles = {
    image: "nos-brandis__loading__image"
};

export default Loading;
