import InventoryDashboardActionTypes from "./InventoryDashboardActionTypes";
import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import { FILTER_CATEGORIES, FILTER_CATEGORIES_SERVER_ENUM } from "../../../utils/dashboard-config";

const InventoryDashboardActions = {
    setFromDate: payload => ({
        type: InventoryDashboardActionTypes.SET_FROM_DATE,
        payload
    }),

    setToDate: payload => ({
        type: InventoryDashboardActionTypes.SET_TO_DATE,
        payload
    }),

    setFilterCategoryOptions: payload => ({
        type: InventoryDashboardActionTypes.SET_FILTER_OPTIONS,
        payload
    }),

    addFilter: payload => ({ type: InventoryDashboardActionTypes.ADD_FILTER, payload }),

    removeFilter: payload => ({ type: InventoryDashboardActionTypes.REMOVE_FILTER, payload }),

    addView: payload => ({ type: InventoryDashboardActionTypes.ADD_VIEW, payload }),

    removeView: payload => ({ type: InventoryDashboardActionTypes.REMOVE_VIEW, payload }),

    beginFetch: () => ({ type: InventoryDashboardActionTypes.BEGIN_FETCH }),

    fetchSuccess: () => ({ type: InventoryDashboardActionTypes.FETCH_COMPLETE }),

    fetchFailure: payload => ({ type: InventoryDashboardActionTypes.FETCH_FAILURE, payload }),

    setFilteredData: payload => ({
        type: InventoryDashboardActionTypes.SET_FILTERED_DATA,
        payload
    }),

    updateView: payload => (dispatch, getState) => {
        if (getState().inventory.views.includes(payload))
            dispatch(InventoryDashboardActions.removeView(payload));
        else dispatch(InventoryDashboardActions.addView(payload));
    },

    getFilterCategoryOptions: category => dispatch => {
        api.get(URL.FILTERS[category]).then(response => {
            dispatch(
                InventoryDashboardActions.setFilterCategoryOptions({
                    [FILTER_CATEGORIES[category]]: response.data
                }),
            );
        });
    },

    addFilterToStore: payload => (dispatch, getState) => {
        const { category, value: newValue } = payload;

        const filterCategoryObject = getState().inventory.filters.find(
            item => item.name === FILTER_CATEGORIES_SERVER_ENUM[category],
        );

        const remainingFilterObjects = getState().inventory.filters.filter(
            item => item.name !== FILTER_CATEGORIES_SERVER_ENUM[category],
        );

        if (filterCategoryObject) {
            // If the filter category exists in the filters array,
            // update the filters array
            const { name, values: oldValues } = filterCategoryObject;
            const newFilterSet = { name, values: [...oldValues, newValue] };

            dispatch(
                InventoryDashboardActions.addFilter([...remainingFilterObjects, newFilterSet]),
            );
        }

        if (!filterCategoryObject)
            // If the filter category does not exist in the filters array
            // create a new filter category and update the filters array

            dispatch(
                InventoryDashboardActions.addFilter([
                    ...remainingFilterObjects,
                    {
                        name: FILTER_CATEGORIES_SERVER_ENUM[category],
                        values: [newValue]
                    }
                ]),
            );
    },

    removeFilterFromStore: payload => (dispatch, getState) => {
        const { category, value: valueToBeRemoved } = payload;

        const filterCategoryObject = getState().inventory.filters.find(
            item => item.name === FILTER_CATEGORIES_SERVER_ENUM[category],
        );

        const remainingFilterObjects = getState().inventory.filters.filter(
            item => item.name !== FILTER_CATEGORIES_SERVER_ENUM[category],
        );

        const { name, values } = filterCategoryObject;

        const newValueSet = values.filter(item => item !== valueToBeRemoved);

        switch (newValueSet.length) {
            case 0:
                dispatch(InventoryDashboardActions.removeFilter([...remainingFilterObjects]));
                break;
            default:
                dispatch(
                    InventoryDashboardActions.removeFilter([
                        ...remainingFilterObjects,
                        { name, values: newValueSet }
                    ]),
                );
        }
    },

    resetFilters: () => ({ type: InventoryDashboardActionTypes.RESET_FILTERS }),

    applyFiltersAndFetchData: () => (dispatch, getState) => {
        const [selectedDashboard] = getState().dashboard.selected;

        const { filters, dateRange, views } = getState().inventory;

        const requestBody = {
            type: selectedDashboard.value,
            fromDate: dateRange.from,
            toDate: dateRange.to,
            filters,
            views
        };

        // console.log("clicked", requestBody);
        dispatch(InventoryDashboardActions.beginFetch());

        api.post(URL.DAHSBOARD.DETAILS, requestBody)
            .then(response => {
                const { records } = response.data.data;

                if (records) dispatch(InventoryDashboardActions.setFilteredData(records));
                else dispatch(InventoryDashboardActions.setFilteredData([]));

                dispatch(InventoryDashboardActions.fetchSuccess());
            })
            .catch(error => {
                dispatch(InventoryDashboardActions.fetchFailure(error));
            });
    }
};

export default InventoryDashboardActions;

// dispatch(
//   InventoryDashboardActions.setFilterCategoryOptions({
//       [FILTER_CATEGORIES[category]]: response.data
//   }),
// );
