const SalesDashboardActionTypes = {
    BEGIN_FETCH: "sales/BEGIN_FETCH",
    FETCH_COMPLETE: "sales/FETCH_COMPLETE",
    FETCH_FAILURE: "sales/FETCH_FAILURE",
    SET_FROM_DATE: "sales/SET_FROM_DATE",
    SET_TO_DATE: "sales/SET_TO_DATE",
    SET_FILTER_OPTIONS: "sales/SET_FILTER_OPTIONS",
    ADD_FILTER: "sales/ADD_FILTER",
    REMOVE_FILTER: "sales/REMOVE_FILTER",
    ADD_VIEW: "sales/ADD_VIEW",
    REMOVE_VIEW: "sales/REMOVE_VIEW",
    RESET_FILTERS: "sales/RESET_FILTERS",
    SET_FILTERED_DATA: "sales/SET_FILTERED_DATA",
    CLEAR_FILTERED_DATE: "sales/CLEAR_FILTERED_DATE",
    SET_SALE_TYPE: "sales/SET_SALE_TYPE",
    SET_SUMMARY_DATA: "sales/SET_SUMMARY_DATA"
};

export default SalesDashboardActionTypes;
