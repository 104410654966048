import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "../styles/button.scss";

const Button = props => {
    const { text, secondary, onClick } = props;
    const buttonStyles = classnames({
        [Button.styles.primaryButton]: !secondary,
        [Button.styles.secondaryButton]: secondary
    });

    return (
        <button onClick={onClick} className={buttonStyles} type="button">
            {text}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    secondary: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
    text: "Click",
    secondary: false
};

Button.styles = {
    container: "nos-brandis__button__container",
    primaryButton: "nos-brandis__button__btn-primary",
    secondaryButton: "nos-brandis__button__btn-secondary"
};

export default Button;
