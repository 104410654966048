import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown-select";
import { APPLICATION, CLIENT } from "../../../config";
import { DASHBOARD_MENU } from "../../utils";
import VERBIAGE from "../../../utils/enums/verbiage";

import "../styles/header.scss";

const Header = props => {
    const { onMenuSelect, selectedOption } = props;
    return (
        <div className={Header.styles.rootContainer}>
            <div className={Header.styles.brandingContainer}>
                <img
                  className={Header.styles.clientLogo}
                  alt="client-logo"
                  src={CLIENT.BRANDIS.LOGO}
                />
                <div className={Header.styles.applicationName}>{APPLICATION.NAME}</div>
            </div>
            <div className={Header.styles.userInfoContainer}>
                <span className={Header.styles.currentDashboardLabel}>
                    {VERBIAGE.HEADER.CURRENT_DASHBOARD}
                    &nbsp;
                </span>
                <Dropdown
                  options={DASHBOARD_MENU}
                  onChange={onMenuSelect}
                  values={selectedOption}
                  placeholder="Select an option"
                />
            </div>
        </div>
    );
};

Header.styles = {
    rootContainer: "nos-brandis__header-container-root",
    brandingContainer: "nos-brandis__header-container-branding",
    clientLogo: "nos-brandis__header-container-branding-client-logo",
    userInfoContainer: "nos-brandis__header-container-info-container",
    applicationName: "nos-brandis__header-application-name",
    currentDashboardLabel: "nos-brandis__header-container-info-label"
};

Header.propTypes = {
    selectedOption: PropTypes.array.isRequired,
    onMenuSelect: PropTypes.func.isRequired
};

export default Header;
