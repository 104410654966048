import React from "react";
import PropTypes from "prop-types";
import SimpleCheckbox from "react-simple-checkbox";
import { FILTER_CHECKBOX_COLOR /* DEFAULT_PRODUCT_ICON */ } from "../../utils/dashboard-config";

import "../styles/checkbox.scss";

const Checkbox = props => {
    const {
        label,
        value,
        // icon,
        category,
        checked,
        addFilterToStore,
        removeFilterFromStore
    } = props;

    const handleFilterUpdate = selectedValue =>
        checked
            ? removeFilterFromStore({ category, value: selectedValue })
            : addFilterToStore({ category, value: selectedValue });

    const handleCheckedChange = selectedValue => handleFilterUpdate(selectedValue);

    return (
        <div className={Checkbox.styles.mainContainer}>
            <SimpleCheckbox
              color={FILTER_CHECKBOX_COLOR}
              onChange={() => handleCheckedChange(value)}
              checked={checked}
              borderThickness={1}
            />
            <div className={Checkbox.styles.labelContainer}>
                {/* <img className={Checkbox.styles.icon} src={icon} alt="icon" /> */}
                <span className={Checkbox.styles.labelText}>{label.toUpperCase()}</span>
            </div>
        </div>
    );
};

Checkbox.styles = {
    icon: "nos-brandis__checkbox__icon",
    labelText: "nos-brandis__checkbox__text",
    mainContainer: "nos-brandis__checkbox__container-main",
    labelContainer: "nos-brandis__checkbox__container-label"
};

Checkbox.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    // icon: PropTypes.string,
    checked: PropTypes.bool,
    category: PropTypes.string.isRequired,
    addFilterToStore: PropTypes.func.isRequired,
    removeFilterFromStore: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
    label: "",
    value: "",
    checked: false
    // icon: DEFAULT_PRODUCT_ICON
};

export default Checkbox;
