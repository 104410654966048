import React from "react";
import PropTypes from "prop-types";

import { FILTER_CATEGORIES } from "../../utils/dashboard-config";
import DateFilter from "./DateFilter";
import SaleTypeFilter from "./SaleTypeFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import Button from "./Button";
import SelectableButton from "./SelectableButton";
import { VERBIAGE } from "../../../utils/enums";
import { CHART_VIEW_LABEL, CHART_VIEW_SERVER_ENUMS } from "../../utils/chart-config";
import { getSliceIndex } from "../../utils/selector-utils";
import "../styles/filter-selector.scss";

const FilterSelector = props => {
    const {
        viewMenu,
        setToDate,
        setFromDate,
        setSaleType,
        addFilterToStore,
        filterCategories,
        selectedDashboardStore,
        getFilterCategoryOptions,
        removeFilterFromStore,
        handleViewMenuClick,
        selectedDashboard,
        handleCancelClick,
        handleResetClick,
        handleApplyClick
    } = props;
    return (
        <div className={FilterSelector.styles.mainContainer}>
            <div className={FilterSelector.styles.topContainer}>
                <div className={FilterSelector.styles.stripSelector}>
                    <div className={FilterSelector.styles.dateRangeContainer}>
                        {filterCategories.includes(FILTER_CATEGORIES.DATE) && (
                            <DateFilter
                              setToDate={setToDate}
                              setFromDate={setFromDate}
                              x
                              dateRange={selectedDashboardStore.dateRange}
                            />
                        )}
                        {filterCategories.includes(FILTER_CATEGORIES.SALE_TYPE) && (
                            <SaleTypeFilter
                              values={selectedDashboardStore.saleType}
                              setSaleType={setSaleType}
                            />
                        )}
                    </div>
                    {filterCategories
                        .slice(getSliceIndex(selectedDashboard), filterCategories.length)
                        .map(category => (
                            <MultiSelectFilter
                              category={category}
                              filters={selectedDashboardStore.filters}
                              filterOptions={selectedDashboardStore.filterOptions}
                              addFilterToStore={addFilterToStore}
                              removeFilterFromStore={removeFilterFromStore}
                              getFilterCategoryOptions={getFilterCategoryOptions}
                            />
                        ))}
                </div>
                <div className={FilterSelector.styles.viewSelector}>
                    <div className={FilterSelector.styles.viewLabel}>{VERBIAGE.LABELS.VIEWS}</div>
                    <div className={FilterSelector.styles.menuContainer}>
                        {viewMenu.map(menu => (
                            <div className={FilterSelector.styles.menuItemWrapper}>
                                <SelectableButton
                                  value={menu}
                                  text={CHART_VIEW_LABEL[menu]}
                                  handleClick={handleViewMenuClick}
                                  selected={selectedDashboardStore.views.includes(
                                        CHART_VIEW_SERVER_ENUMS[menu],
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={FilterSelector.styles.bottomContainer}>
                <div className={FilterSelector.styles.buttonContainer}>
                    <Button onClick={handleCancelClick} secondary text={VERBIAGE.BUTTONS.CANCEL} />
                </div>
                <div className={FilterSelector.styles.buttonContainer}>
                    <Button onClick={handleResetClick} text={VERBIAGE.BUTTONS.RESET} />
                </div>
                <div className={FilterSelector.styles.buttonContainer}>
                    <Button onClick={handleApplyClick} text={VERBIAGE.BUTTONS.APPLY} />
                </div>
            </div>
        </div>
    );
};

FilterSelector.styles = {
    mainContainer: "nos-brandis__filter-selector__container-main",
    dateRangeContainer: "nos-brandis__filter-selector__container-date-range",
    stripSelector: "nos-brandis__filter-selector__container-selector-strip",
    viewSelector: "nos-brandis__filter-selector__container-selector-view",
    topContainer: "nos-brandis__filter-selector__container-top",
    bottomContainer: "nos-brandis__filter-selector__container-bottom",
    buttonContainer: "nos-brandis__filter-selector__container-button",
    menuContainer: "nos-brandis__filter-selector__container-selector-menu",
    menuItemWrapper: "nos-brandis__filter-selector__menu-item-wrapper",
    viewLabel: "nos-brandis__filter-selector__view-label"
};

FilterSelector.propTypes = {
    setToDate: PropTypes.func.isRequired,
    setFromDate: PropTypes.func.isRequired,
    selectedDashboard: PropTypes.array.isRequired,
    selectedDashboardStore: PropTypes.object.isRequired,
    filterCategories: PropTypes.array.isRequired,
    getFilterCategoryOptions: PropTypes.func.isRequired,
    addFilterToStore: PropTypes.func.isRequired,
    removeFilterFromStore: PropTypes.func.isRequired,
    viewMenu: PropTypes.array.isRequired,
    handleViewMenuClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
    handleResetClick: PropTypes.func.isRequired,
    handleApplyClick: PropTypes.func.isRequired,
    setSaleType: PropTypes.func.isRequired
};

FilterSelector.defaultProps = {};

export default FilterSelector;
