import SalesDashboardActionTypes from "./SalesDashboardActionTypes";
import { DEFAULT_DATE_RANGE, SALE_TYPE_DEFAULT_OPTION } from "../../../utils/dashboard-config";
import VERBIAGE from "../../../../utils/enums/verbiage";

const initialState = {
    isFetching: false,
    fetchComplete: true,
    fetchError: VERBIAGE.EMPTY_STRING,
    filters: [],
    records: [],
    dateRange: {
        from: DEFAULT_DATE_RANGE.FROM,
        to: DEFAULT_DATE_RANGE.TO
    },
    summary: {},
    saleType: SALE_TYPE_DEFAULT_OPTION,
    views: ["category", "city"],
    filterOptions: {}
};

const inventoryDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SalesDashboardActionTypes.BEGIN_FETCH:
            return {
                ...state,
                isFetching: true,
                fetchComplete: false,
                fetchError: VERBIAGE.EMPTY_STRING
            };
        case SalesDashboardActionTypes.FETCH_COMPLETE:
            return {
                ...state,
                isFetching: false,
                fetchComplete: true,
                fetchError: VERBIAGE.EMPTY_STRING
            };
        case SalesDashboardActionTypes.FETCH_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchComplete: true,
                fetchError: action.payload
            };
        case SalesDashboardActionTypes.SET_FILTERED_DATA:
            return {
                ...state,
                records: action.payload
            };
        case SalesDashboardActionTypes.SET_FROM_DATE:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    from: action.payload
                }
            };
        case SalesDashboardActionTypes.SET_TO_DATE:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    to: action.payload
                }
            };
        case SalesDashboardActionTypes.SET_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    ...action.payload
                }
            };
        case SalesDashboardActionTypes.ADD_FILTER:
            return {
                ...state,
                filters: action.payload
            };
        case SalesDashboardActionTypes.REMOVE_FILTER:
            return {
                ...state,
                filters: action.payload
            };
        case SalesDashboardActionTypes.ADD_VIEW:
            return {
                ...state,
                views: [...state.views, action.payload]
            };
        case SalesDashboardActionTypes.REMOVE_VIEW:
            return {
                ...state,
                views: state.views.filter(item => item !== action.payload)
            };
        case SalesDashboardActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: [],
                dateRange: {
                    from: DEFAULT_DATE_RANGE.FROM,
                    to: DEFAULT_DATE_RANGE.TO
                },
                views: []
            };
        case SalesDashboardActionTypes.SET_SALE_TYPE:
            return {
                ...state,
                saleType: [...action.payload]
            };
        case SalesDashboardActionTypes.SET_SUMMARY_DATA:
            return {
                ...state,
                summary: { ...action.payload }
            };
        default:
            return state;
    }
};

export default inventoryDashboardReducer;
