const InventoryDashboardActionTypes = {
    BEGIN_FETCH: "inventory/BEGIN_FETCH",
    FETCH_COMPLETE: "inventory/FETCH_COMPLETE",
    FETCH_FAILURE: "inventory/FETCH_FAILURE",
    SET_FROM_DATE: "inventory/SET_FROM_DATE",
    SET_TO_DATE: "inventory/SET_TO_DATE",
    SET_FILTER_OPTIONS: "inventory/SET_FILTER_OPTIONS",
    ADD_FILTER: "inventory/ADD_FILTER",
    REMOVE_FILTER: "inventory/REMOVE_FILTER",
    ADD_VIEW: "inventory/ADD_VIEW",
    REMOVE_VIEW: "inventory/REMOVE_VIEW",
    RESET_FILTERS: "inventory/RESET_FILTERS",
    SET_FILTERED_DATA: "inventory/SET_FILTERED_DATA",
    CLEAR_FILTERED_DATE: "inventory/CLEAR_FILTERED_DATE"
};

export default InventoryDashboardActionTypes;
