import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Icon from "react-icons-kit";
import { plus } from "react-icons-kit/fa/plus";
import { minus } from "react-icons-kit/fa/minus";
import "../styles/filter-strip.scss";
import { VERBIAGE } from "../../../utils/enums/index";
import FilterPills from "./FilterPills";
import { FILTER_STRIP_LABEL } from "../../utils/dashboard-config";

const FilterStrip = props => {
    const { isFilterStripExpanded, setFilterStripToggleState, selectedDashboardStore } = props;

    const fromDate = moment(selectedDashboardStore.dateRange.from).format("MMM DD YYYY");

    const toDate = moment(selectedDashboardStore.dateRange.to).format("MMM DD YYYY");

    const dateRangeText = `${fromDate} to ${toDate}`;

    return (
        <div className={FilterStrip.styles.mainContainer}>
            <button
              type="button"
              className={FilterStrip.styles.toggleButton}
              onClick={() => setFilterStripToggleState(!isFilterStripExpanded)}
            >
                <Icon icon={isFilterStripExpanded ? minus : plus} size={10} />
            </button>
            <span className={FilterStrip.styles.filterLabel}>{VERBIAGE.LABELS.FILTER}</span>
            <div className={FilterStrip.styles.pillContainer}>
                {dateRangeText && (
                    <FilterPills
                      key="date"
                      name={FILTER_STRIP_LABEL.DATE}
                      values={[dateRangeText]}
                    />
                )}
                {selectedDashboardStore.saleType && (
                    <FilterPills
                      key="saleType"
                      name={FILTER_STRIP_LABEL.SALETYPE}
                      values={selectedDashboardStore.saleType[0].label}
                    />
                )}
                {selectedDashboardStore.filters.length > 0 &&
                    selectedDashboardStore.filters.map(filter => (
                        <FilterPills
                          key={filter.name}
                          badge={filter.values.length}
                          name={FILTER_STRIP_LABEL[filter.name]}
                          values={filter.values}
                        />
                    ))}
            </div>
        </div>
    );
};

FilterStrip.styles = {
    mainContainer: "nos-brandis__filter-strip__container-main",
    toggleButton: "nos-brandis__filter-strip__toggle-button",
    filterLabel: "nos-brandis__filter-strip__filter-label",
    pillContainer: "nos-brandis__filter-strip__container-pill"
};

FilterStrip.propTypes = {
    selectedDashboardStore: PropTypes.object.isRequired,
    isFilterStripExpanded: PropTypes.bool.isRequired,
    setFilterStripToggleState: PropTypes.func.isRequired
};

export default FilterStrip;
